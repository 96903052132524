import { loginRequest } from "../auth/authConfig";
import { InteractionType } from "@azure/msal-browser";
import auth from "../auth/msal-authprovider.js";
import { msalInstance } from "../auth/authConfig";
import store from "../store";
import { EventBus } from "../event/eventBus.js";
//import { logger } from "@/util/logger.js";

export function registerGuard(router) {
  var hasAnyRoles = function (roles) {
    var args = Array.prototype.slice.call(roles);
    return store.state.user?.roles?.some(r => args.includes(r)) === true;
  };

  router.beforeEach(async (to, from, next) => {
    //logger.log("Guard: ", from.path, to.path);
    await msalInstance.initialize();
    if (!to.meta.allowInsecure === true) {
      //logger.log("Guard: requires auth");
      //console.log(to.path, to.fullPath, to.meta.allowInsecure);
      const request = {
        ...loginRequest,
        redirectStartPage: to.path === "/" ? "/home" : to.fullPath
      };

      EventBus.$emit("auth-started");
      var r = await auth.signIn(InteractionType.Redirect, request).finally(() => {
        EventBus.$emit("auth-finished");
      });

      //logger.log("Guard: authenticated", r.success);
      if (r.success && hasAnyRoles(["ADMIN", "PROBLEM_SOLVER", "CVEP_USER"])) {
        next();
      } else {
        next("/auth-failed");
      }
    } else {
      await msalInstance.handleRedirectPromise();
      next();
    }
  });
}
